export default class GeometryComponentFilter {
	constructor() {
		GeometryComponentFilter.constructor_.apply(this, arguments);
	}
	filter(geom) {}
	getClass() {
		return GeometryComponentFilter;
	}
	get interfaces_() {
		return [];
	}
}
GeometryComponentFilter.constructor_ = function () {};
