export default class SegmentString {
	constructor() {
		SegmentString.constructor_.apply(this, arguments);
	}
	getCoordinates() {}
	size() {}
	getCoordinate(i) {}
	isClosed() {}
	setData(data) {}
	getData() {}
	getClass() {
		return SegmentString;
	}
	get interfaces_() {
		return [];
	}
}
SegmentString.constructor_ = function () {};
