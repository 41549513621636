export default class Noder {
	constructor() {
		Noder.constructor_.apply(this, arguments);
	}
	computeNodes(segStrings) {}
	getNodedSubstrings() {}
	getClass() {
		return Noder;
	}
	get interfaces_() {
		return [];
	}
}
Noder.constructor_ = function () {};
