export default class ConstraintSplitPointFinder {
	constructor() {
		ConstraintSplitPointFinder.constructor_.apply(this, arguments);
	}
	findSplitPoint(seg, encroachPt) {}
	getClass() {
		return ConstraintSplitPointFinder;
	}
	get interfaces_() {
		return [];
	}
}
ConstraintSplitPointFinder.constructor_ = function () {};
