export default class Polygonal {
	constructor() {
		Polygonal.constructor_.apply(this, arguments);
	}
	getClass() {
		return Polygonal;
	}
	get interfaces_() {
		return [];
	}
}
Polygonal.constructor_ = function () {};
