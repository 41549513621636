export default class SegmentIntersector {
	constructor() {
		SegmentIntersector.constructor_.apply(this, arguments);
	}
	processIntersections(e0, segIndex0, e1, segIndex1) {}
	isDone() {}
	getClass() {
		return SegmentIntersector;
	}
	get interfaces_() {
		return [];
	}
}
SegmentIntersector.constructor_ = function () {};
