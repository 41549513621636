export default class EdgeSetIntersector {
	constructor() {
		EdgeSetIntersector.constructor_.apply(this, arguments);
	}
	getClass() {
		return EdgeSetIntersector;
	}
	get interfaces_() {
		return [];
	}
}
EdgeSetIntersector.constructor_ = function () {};
